// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faqs-index-jsx": () => import("./../../../src/pages/faqs/index.jsx" /* webpackChunkName: "component---src-pages-faqs-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-samples-index-jsx": () => import("./../../../src/pages/samples/index.jsx" /* webpackChunkName: "component---src-pages-samples-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */)
}

